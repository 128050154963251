.chat {
  position: fixed;
  left: 0;
  width: 100%;
  transition: .2s;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  background: #fff;
  &-body {
    overflow-y: hidden;
    height: 100%;
    padding: 10px 10px 10px 10px;
    vertical-align: bottom;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &-only {
      overflow-y: scroll;
    }
    &-inner {
      width: 100%;
    }
    &-item {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 30px;
      &-img {
        width: 60px;
        height: 60px;
        padding: 0;
        margin: 0;
        overflow: hidden;
        border-radius: 50%;
        img {
          width: 100%;
        }
      }
      &-texts {
        width: calc(100% - 80px);
        color: #666;
        font-size: 42px;
        &-date {
          margin: 0 10px 0 0;
        }
        &-name {
          font-weight: bold;
          margin: 0 16px 0 0;
        }
      }
      &-super {
        margin: 0 0 10px;
        width: 100%;
        &-head {
          display: flex;
          align-items: center;
          border-radius: 4px 4px 0 0;
          padding: 10px 22px;
          &--hight {
            background: #c13329;
          }
          &--low {
            background: #db961f;
          }
        }
        &-img {
          width: 60px;
          height: 60px;
          padding: 0;
          margin: 0;
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 100%;
          }
        }
        &-group {
          font-size: 42px;
          padding-left: 20px;
          &-name ,
          &-amount {
            margin: 0;
            color: #fff;
          }
          &-amount {
            font-weight: bold;
          }
        }
        &-text {
          padding: 10px 22px;
          border-radius: 0 0 4px 4px;
          color: #fff;
          font-size: 40px;
          &--hight {
            background: #d4554c;
          }
          &--low {
            background: #f3a620;
          }
        }
      }
    }
  }
  &-head {
    width: 100%;
    border-bottom: 1px solid #666;
    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
    &-title {
      font-weight: 600;
      font-size: 16px;
      color: #666;
      letter-spacing: 0;
      line-height: 32px;
      margin: 0;
      font-family: "evo-bold";
      text-transform: uppercase;
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: right;
      width: 24px;
      height: 24px;
      color: #666;
    }
  }
  &-super {
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    padding: 20px 0;
    &-item {
      display: flex;
      align-items: center;
      border-radius: 4px;
      margin: 0 5px;
      padding: 6px 8px 5px;
      &:last-child {
        margin: 0 25px 0 5px;
      }
      &:first-child {
        margin: 0 5px 0 25px;
      }
      &-img {
        margin: 0 10px 0 0;
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      &-amount {
        color: #fff;
        font-weight: bold;
        font-size: 30px;
      }
      &--hight {
        background: #d4554c;
        background: linear-gradient(180deg, rgba(193,51,41,1) 0%, rgba(193,51,41,1) 51%, rgba(212,85,76,1) 52%, rgba(212,85,76,1) 100%);
      }
      &--low {
        background: #f3a620;
        background: linear-gradient(180deg, rgba(219,150,31,1) 0%, rgba(219,150,31,1) 51%, rgba(243,166,32,1) 52%, rgba(243,166,32,1) 100%);
      }
    }
  }
  &-input {
    background: none;
    border: none;
    outline: none;
    color: #fff;
    padding: 4px 42px 4px 12px;
    height: 100%;
    width: 100%;
    &::placeholder {
      color: #fff;
    }
    &-limit {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgba(0,0,0,.8);
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      &-attention {
        text-align: center;
        color: #fff;
        margin: 0;
      }
    }
    &-group {
      display: flex;
      align-items: center;
      position: relative;
      border: 1px solid #666;
      border-radius: 3px;
      overflow: hidden;
      width: calc(100% - 92px);
    }
    &-container {
      border-top: 1px solid #666;
      display: flex;
      padding: 10px 15px;
      flex-flow: 1;
      position: relative;
    }
    &-img {
      margin: 0 10px 0 0;
      padding: 0;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    &-send {
      position: absolute;
      top: 0;
      right: 0;
      width: 34px;
      height: 100%;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      background: #666;
      justify-content: center;
      svg {
        font-size: 14px;
        color: #fff;
      }
      &:disabled {
        opacity: .4;
      }
    }
    &-super {
      width: 36px;
      height: 36px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      background: none;
      svg {
        font-size: 28px;
        color: #C88CFF;
      }
      &:disabled {
        opacity: .4;
      }
    }
  }
}
